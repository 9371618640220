/* dashed line for easy setup component */
.setup-box::after {
    float: left;
    width: 0;
    white-space: nowrap;
    content: "- - - - - - - - - - - - - - - - - - - - - - - - -";
    color: #ccc;
    font-weight: bold;
    position: absolute;
    transform: translate(30px, 0px)
}

/* hide dashed line for last child in easy setup component */
.setup-box-stack:last-child .setup-box::after {
    content: "";
}

@media (max-width: 500px) {
    .setup-stack {
      padding-left: 4rem !important;
    }
}
  