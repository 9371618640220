@media (max-width: 500px) {
    .footer-links {
      flex-direction: column !important;
    }
   
    
}

@media (max-width: 500px) {
    .footer-company-info {
        justify-content: center !important;
    }
}

@media (max-width: 500px) {

    .footer-company-text {
        display: none !important;
    }
}
