@media (max-width: 500px) {
    #call-to-action {
      height: 75vh !important;
    }
    
   
}
@media (max-width: 500px) {
    .partners-image-box {
      flex-direction: column !important;
    }
   
}

@media (max-width: 500px) {
    .image-2 {
      margin: 0px !important;
      width: 80vw !important;
    }

}

@media (max-width: 500px) {
  .image-1 {
    width: 80vw !important;
  }

 
}
@media (max-width: 500px) {
    .image-2 {
      padding-top: 1rem !important;
    }
    
   
}