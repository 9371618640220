@media (max-width: 500px) {
    .product-card-stack {
      overflow-x: auto;
      overflow-y: hidden;
    }
   
    
}
@media (max-width: 500px) {
  .product-stack {
    padding-left: 4rem !important;
  }
 
  
}
